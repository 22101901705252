import React from 'react';
import { bool, node } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import classNames from 'classnames';

import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import * as validators from '../../../util/validators';
import { Form, PrimaryButton, FieldTextInput, FieldSelect } from '../../../components';

import css from './SignupForm.module.css';
import { useConfiguration } from '../../../context/configurationContext';

const SignupFormComponent = props => {
  const config = useConfiguration();
  const registrationCode = config?.customAppConfig?.signup?.registrationVendorCode;

  return (
    <FinalForm
      {...props}
      mutators={{ ...arrayMutators }}
      render={fieldRenderProps => {
        const {
          rootClassName,
          className,
          formId,
          handleSubmit,
          inProgress,
          invalid,
          intl,
          termsAndConditions,
          values,
        } = fieldRenderProps;

        // email
        const emailRequired = validators.required(
          intl.formatMessage({
            id: 'SignupForm.emailRequired',
          })
        );
        const emailValid = validators.emailFormatValid(
          intl.formatMessage({
            id: 'SignupForm.emailInvalid',
          })
        );

        // password
        const passwordRequiredMessage = intl.formatMessage({
          id: 'SignupForm.passwordRequired',
        });
        const passwordMinLengthMessage = intl.formatMessage(
          {
            id: 'SignupForm.passwordTooShort',
          },
          {
            minLength: validators.PASSWORD_MIN_LENGTH,
          }
        );
        const passwordMaxLengthMessage = intl.formatMessage(
          {
            id: 'SignupForm.passwordTooLong',
          },
          {
            maxLength: validators.PASSWORD_MAX_LENGTH,
          }
        );

        const registrationCodeMessage = intl.formatMessage({
          id: 'SignupForm.registrationCodeInvalid',
        })

        const passwordMinLength = validators.minLength(
          passwordMinLengthMessage,
          validators.PASSWORD_MIN_LENGTH
        );
        const passwordMaxLength = validators.maxLength(
          passwordMaxLengthMessage,
          validators.PASSWORD_MAX_LENGTH
        );
        const passwordRequired = validators.requiredStringNoTrim(passwordRequiredMessage);
        const passwordValidators = validators.composeValidators(
          passwordRequired,
          passwordMinLength,
          passwordMaxLength
        );

        const registrationCodeValidators = validators.isEqualTo(registrationCodeMessage, [registrationCode, "", undefined])
        const classes = classNames(rootClassName || css.root, className);
        const submitInProgress = inProgress;
        const submitDisabled = invalid || submitInProgress;

        return (
          <Form className={classes} onSubmit={handleSubmit}>
            <div>
              <FieldTextInput
                type="email"
                id={formId ? `${formId}.email` : 'email'}
                name="email"
                autoComplete="email"
                label={intl.formatMessage({
                  id: 'SignupForm.emailLabel',
                })}
                placeholder={intl.formatMessage({
                  id: 'SignupForm.emailPlaceholder',
                })}
                validate={validators.composeValidators(emailRequired, emailValid)}
              />

              <FieldTextInput
                className={css.password}
                type="password"
                id={formId ? `${formId}.password` : 'password'}
                name="password"
                autoComplete="new-password"
                label={intl.formatMessage({
                  id: 'SignupForm.passwordLabel',
                })}
                placeholder={intl.formatMessage({
                  id: 'SignupForm.passwordPlaceholder',
                })}
                validate={passwordValidators}
              />

              <FieldTextInput
                className={css.password}
                type="text"
                id={formId ? `${formId}.registrationCode` : 'registrationCode'}
                name="registrationCode"
                label={intl.formatMessage({
                  id: 'SignupForm.registrationCodeLabel',
                })}
                placeholder={intl.formatMessage({
                  id: 'SignupForm.registrationCodePlaceholder',
                })}
                validate={registrationCodeValidators}
              />
              <p><small>{intl.formatMessage({ id: "SignupForm.registrationCodeDescription" })}</small></p>

              <hr />

              <div className={css.name}>
                <FieldTextInput
                  className={css.firstNameRoot}
                  type="text"
                  id={formId ? `${formId}.fname` : 'fname'}
                  name="fname"
                  autoComplete="given-name"
                  label={intl.formatMessage({
                    id: `SignupForm.${values.registrationCode ? 'companyNameLabel' : 'firstNameLabel'}`,
                  })}
                  placeholder={intl.formatMessage({
                    id: `SignupForm.${values.registrationCode ? 'companyNamePlaceholder' : 'firstNamePlaceholder'}`,
                  })}
                  validate={validators.required(intl.formatMessage({
                    id: `SignupForm.${values.registrationCode ? 'companyNameRequired' : 'firstNameRequired'}`,
                  }))}
                />
                <FieldTextInput
                  className={css.lastNameRoot}
                  type="text"
                  id={formId ? `${formId}.lname` : 'lname'}
                  name="lname"
                  autoComplete="family-name"
                  label={intl.formatMessage({
                    id: `SignupForm.${values.registrationCode ? 'fullNameLabel' : 'lastNameLabel'}`,
                  })}
                  placeholder={intl.formatMessage({
                    id: `SignupForm.${values.registrationCode ? 'fullNamePlaceholder' : 'lastNamePlaceholder'}`,
                  })}
                  validate={validators.required(intl.formatMessage({
                    id: `SignupForm.${values.registrationCode ? 'fullNameRequired' : 'lastNameRequired'}`,
                  }))}
                />
              </div>

            </div>

            <div className={css.bottomWrapper}>
              {termsAndConditions}
              <PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
                <FormattedMessage id="SignupForm.signUp" />
              </PrimaryButton>
            </div>
          </Form>
        );
      }}
    />
  )
};

SignupFormComponent.defaultProps = { inProgress: false };

SignupFormComponent.propTypes = {
  inProgress: bool,
  termsAndConditions: node.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const SignupForm = compose(injectIntl)(SignupFormComponent);
SignupForm.displayName = 'SignupForm';

export default SignupForm;
